



































































import { Game, GM } from '@/api'
import { ItemFactory } from '@/services/Factories'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ItemIconCard: () => import('../Item/ItemIconCard.vue')
  }
})
export default class CharacterInventory extends Vue {
  @Prop() data

  categories = ['Equipment', 'Main', 'Etc', 'Specialist', 'Costume']
  warehouseItems = []

  async mounted() {
    await this.getWarehouse()
  }

  async getWarehouse() {
    const { characterId } = this.$route.params
    const { result } = this.$route.path.includes('view')
      ? await GM.getWarehouse(characterId)
      : await Game.getWarehouse(characterId)

    if (!result) return

    const warehouseItemIds = result
      .sort((a: any, b: any) => a.slot - b.slot)
      .map(({ itemInstance }) => itemInstance.itemVNum)
    const names = await Game.getItemsNames(warehouseItemIds)
    const icons = await Game.getItemsIcons(warehouseItemIds)
    const itemData = names.map((item, i) => Object.assign({}, item, icons[i]))

    const warehouseItems = result.map(item => {
      const { name, iconId } = itemData.find(e => e.vnum == item.itemInstance.itemVNum)

      return new ItemFactory(item).buildItem({ name, iconId })
    })

    this.warehouseItems = warehouseItems
  }
}
